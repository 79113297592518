@font-face {
  font-family: 'Jost';
  src: url("../assets/fonts/jost/Jost-500-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'Jost';
  src: url("../assets/fonts/jost/Jost-300-Light.ttf") format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Jost';
  src: url("../assets/fonts/jost/Jost-700-Bold.ttf") format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Jost Bold';
  src: url("../assets/fonts/jost/Jost-700-Bold.ttf") format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Jost Thin';
  src: url("../assets/fonts/jost/Jost-200-Thin.ttf") format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Milliard Book';
  src: url("../assets/fonts/milliard/Milliard-Book.otf") format('opentype');
}
@font-face {
  font-family: 'Milliard Book';
  src: url("../assets/fonts/milliard/Milliard-Semibold.otf") format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'Milliard Book';
  src: url("../assets/fonts/milliard/Milliard-Book.otf") format('opentype');
  font-weight: bolder;
}

@font-face {
  font-family: 'Milliard Bold';
  src: url("../assets/fonts/milliard/Milliard-Bold.otf") format('opentype');
  font-weight: bolder;
}

@font-face {
  font-family: 'Milliard Semibold';
  src: url("../assets/fonts/milliard/Milliard-Semibold.otf") format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Yu Gothic';
  src: url("../assets/fonts/yu/yu.ttf") format('truetype');
}

@font-face {
  font-family: 'YesJapan Gothic';
  src: url("../assets/fonts/yu/YESJAPAN-GOTHIC-UI-TRIM.otf") format('opentype');
}

@font-face {
  font-family: "Mont Heavy";
  src: url("../assets/fonts/mont/Mont-Heavy.otf") format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: "Mont Extra Light";
  src: url("../assets/fonts/mont/Mont-ExtraLight.otf") format('opentype');
  font-weight: lighter;
}

@font-face {
  font-family: "Corporate";
  src: url("https://d32tv5vz8ua1sf.cloudfront.net/fonts/Corporate-Logo-Rounded.ttf") format('truetype');
  font-weight: thick;
}

@font-face {
  font-family: 'Noto Sans JP Thin';
  src: url("https://d32tv5vz8ua1sf.cloudfront.net/fonts/NotoSansJP-Thin.otf") format('opentype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Noto Sans JP Light';
  src: url("https://d32tv5vz8ua1sf.cloudfront.net/fonts/NotoSansJP-Light.otf") format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url("https://d32tv5vz8ua1sf.cloudfront.net/fonts/NotoSansJP-Regular.otf") format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans JP Bold';
  src: url("https://d32tv5vz8ua1sf.cloudfront.net/fonts/NotoSansJP-Bold.otf") format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Noto Sans JP Black';
  src: url("https://d32tv5vz8ua1sf.cloudfront.net/fonts/NotoSansJP-Black.otf") format('opentype');
  font-weight: bolder;
}

@font-face {
  font-family: 'Calligraphy';
  src: url("https://d32tv5vz8ua1sf.cloudfront.net/fonts/calligraphy.otf") format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'BIZ UDPGothic';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/BIZUDPGothic-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Riffic Bold';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/RifficFree-Bold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Katahdin Round';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/KatahdinRound-Bold.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Apparat';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/ApparatSemiCond-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Apparat Medium';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/ApparatSemiCond-Medium.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Apparat Semibold';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/ApparatSemiCond-Semibold.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Racing Sans One';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/RacingSansOne-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Tilt Warp';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/TiltWarp-Regular.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Outfit';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/Outfit-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Figtree';
  src: url('https://d32tv5vz8ua1sf.cloudfront.net/fonts/Figtree-VariableFont_wght.ttf') format('truetype');
}