/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

*, ::before, ::after {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-gradient-from-position:  ;

    --tw-gradient-via-position:  ;

    --tw-gradient-to-position:  ;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  ;

    --tw-contain-size:  ;

    --tw-contain-layout:  ;

    --tw-contain-paint:  ;

    --tw-contain-style:  
}

::backdrop {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-gradient-from-position:  ;

    --tw-gradient-via-position:  ;

    --tw-gradient-to-position:  ;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  ;

    --tw-contain-size:  ;

    --tw-contain-layout:  ;

    --tw-contain-paint:  ;

    --tw-contain-style:  
}
.tw-sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    padding: 0;

    margin: -1px;

    overflow: hidden;

    clip: rect(0, 0, 0, 0);

    white-space: nowrap;

    border-width: 0
}
.tw-fixed {

    position: fixed
}
.tw-absolute {

    position: absolute
}
.tw-relative {

    position: relative
}
.tw-inset-0 {

    inset: 0px
}
.tw-inset-24 {

    inset: 6rem
}
.tw-inset-x-0 {

    left: 0px;

    right: 0px
}
.tw--top-2 {

    top: -0.5rem
}
.tw-bottom-0 {

    bottom: 0px
}
.tw--left-3 {

    left: -0.75rem
}
.tw--left-5 {

    left: -1.25rem
}
.tw-left-2 {

    left: 0.5rem
}
.tw--right-3 {

    right: -0.75rem
}
.tw-right-0 {

    right: 0px
}
.tw--top-1 {

    top: -0.25rem
}
.tw--top-3 {

    top: -0.75rem
}
.tw-top-0 {

    top: 0px
}
.tw-z-10 {

    z-index: 10
}
.tw-z-20 {

    z-index: 20
}
.tw-m-0 {

    margin: 0px
}
.tw-mx-2 {

    margin-left: 0.5rem;

    margin-right: 0.5rem
}
.tw-mx-20 {

    margin-left: 5rem;

    margin-right: 5rem
}
.tw-mx-4 {

    margin-left: 1rem;

    margin-right: 1rem
}
.tw-mx-auto {

    margin-left: auto;

    margin-right: auto
}
.tw-my-0 {

    margin-top: 0px;

    margin-bottom: 0px
}
.tw-my-1 {

    margin-top: 0.25rem;

    margin-bottom: 0.25rem
}
.tw-my-2 {

    margin-top: 0.5rem;

    margin-bottom: 0.5rem
}
.tw-my-3 {

    margin-top: 0.75rem;

    margin-bottom: 0.75rem
}
.tw-my-4 {

    margin-top: 1rem;

    margin-bottom: 1rem
}
.tw-my-5 {

    margin-top: 1.25rem;

    margin-bottom: 1.25rem
}
.tw-my-8 {

    margin-top: 2rem;

    margin-bottom: 2rem
}
.tw--ml-1 {

    margin-left: -0.25rem
}
.tw--ml-px {

    margin-left: -1px
}
.tw--mt-px {

    margin-top: -1px
}
.tw-mb-0 {

    margin-bottom: 0px
}
.tw-mb-1 {

    margin-bottom: 0.25rem
}
.tw-mb-12 {

    margin-bottom: 3rem
}
.tw-mb-2 {

    margin-bottom: 0.5rem
}
.tw-mb-3 {

    margin-bottom: 0.75rem
}
.tw-mb-4 {

    margin-bottom: 1rem
}
.tw-mb-6 {

    margin-bottom: 1.5rem
}
.tw-mb-8 {

    margin-bottom: 2rem
}
.tw--ml-1 {

    margin-left: -0.25rem
}
.tw-ml-1 {

    margin-left: 0.25rem
}
.tw-ml-16 {

    margin-left: 4rem
}
.tw-ml-2 {

    margin-left: 0.5rem
}
.tw-ml-3 {

    margin-left: 0.75rem
}
.tw-ml-3\.5 {

    margin-left: 0.875rem
}
.tw-ml-4 {

    margin-left: 1rem
}
.tw-ml-5 {

    margin-left: 1.25rem
}
.tw-ml-6 {

    margin-left: 1.5rem
}
.tw-ml-8 {

    margin-left: 2rem
}
.tw-ml-9 {

    margin-left: 2.25rem
}
.tw-ml-\[10px\] {

    margin-left: 10px
}
.tw-ml-auto {

    margin-left: auto
}
.tw-mr-0 {

    margin-right: 0px
}
.tw-mr-1 {

    margin-right: 0.25rem
}
.tw-mr-1\.5 {

    margin-right: 0.375rem
}
.tw-mr-12 {

    margin-right: 3rem
}
.tw-mr-2 {

    margin-right: 0.5rem
}
.tw-mr-4 {

    margin-right: 1rem
}
.tw-mr-auto {

    margin-right: auto
}
.tw-mt-0 {

    margin-top: 0px
}
.tw-mt-1 {

    margin-top: 0.25rem
}
.tw-mt-10 {

    margin-top: 2.5rem
}
.tw-mt-12 {

    margin-top: 3rem
}
.tw-mt-2 {

    margin-top: 0.5rem
}
.tw-mt-3 {

    margin-top: 0.75rem
}
.tw-mt-4 {

    margin-top: 1rem
}
.tw-mt-5 {

    margin-top: 1.25rem
}
.tw-mt-6 {

    margin-top: 1.5rem
}
.tw-mt-8 {

    margin-top: 2rem
}
.tw-block {

    display: block
}
.tw-inline-block {

    display: inline-block
}
.tw-inline {

    display: inline
}
.tw-flex {

    display: flex
}
.tw-inline-flex {

    display: inline-flex
}
.tw-flow-root {

    display: flow-root
}
.tw-grid {

    display: grid
}
.tw-hidden {

    display: none
}
.tw-h-0 {

    height: 0px
}
.tw-h-1\.5 {

    height: 0.375rem
}
.tw-h-10 {

    height: 2.5rem
}
.tw-h-11 {

    height: 2.75rem
}
.tw-h-12 {

    height: 3rem
}
.tw-h-4 {

    height: 1rem
}
.tw-h-5 {

    height: 1.25rem
}
.tw-h-6 {

    height: 1.5rem
}
.tw-h-8 {

    height: 2rem
}
.tw-h-full {

    height: 100%
}
.tw-h-screen {

    height: 100vh
}
.tw-min-h-max {

    min-height: max-content
}
.tw-w-0 {

    width: 0px
}
.tw-w-0\.5 {

    width: 0.125rem
}
.tw-w-1\.5 {

    width: 0.375rem
}
.tw-w-1\/2 {

    width: 50%
}
.tw-w-1\/3 {

    width: 33.333333%
}
.tw-w-1\/4 {

    width: 25%
}
.tw-w-1\/5 {

    width: 20%
}
.tw-w-10 {

    width: 2.5rem
}
.tw-w-11 {

    width: 2.75rem
}
.tw-w-11\/12 {

    width: 91.666667%
}
.tw-w-12 {

    width: 3rem
}
.tw-w-2\/3 {

    width: 66.666667%
}
.tw-w-2\/5 {

    width: 40%
}
.tw-w-20 {

    width: 5rem
}
.tw-w-24 {

    width: 6rem
}
.tw-w-3\/4 {

    width: 75%
}
.tw-w-3\/5 {

    width: 60%
}
.tw-w-4 {

    width: 1rem
}
.tw-w-4\/5 {

    width: 80%
}
.tw-w-5 {

    width: 1.25rem
}
.tw-w-6 {

    width: 1.5rem
}
.tw-w-8 {

    width: 2rem
}
.tw-w-auto {

    width: auto
}
.tw-w-full {

    width: 100%
}
.tw-min-w-0 {

    min-width: 0px
}
.tw-min-w-max {

    min-width: max-content
}
.tw-max-w-0 {

    max-width: 0px
}
.tw-max-w-3xl {

    max-width: 48rem
}
.tw-max-w-lg {

    max-width: 32rem
}
.tw-max-w-prose {

    max-width: 65ch
}
.tw-flex-1 {

    flex: 1 1 0%
}
.tw-flex-shrink-0 {

    flex-shrink: 0
}
.tw-flex-grow {

    flex-grow: 1
}
.tw-border-collapse {

    border-collapse: collapse
}
.tw-translate-x-0 {

    --tw-translate-x: 0px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-x-\[-25px\] {

    --tw-translate-x: -25px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-x-\[-300px\] {

    --tw-translate-x: -300px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-x-\[-400px\] {

    --tw-translate-x: -400px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-x-\[200px\] {

    --tw-translate-x: 200px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-x-\[300px\] {

    --tw-translate-x: 300px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-x-\[400px\] {

    --tw-translate-x: 400px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-y-0 {

    --tw-translate-y: 0px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-y-\[-100px\] {

    --tw-translate-y: -100px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-translate-y-\[400px\] {

    --tw-translate-y: 400px;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-rotate-0 {

    --tw-rotate: 0deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-rotate-6 {

    --tw-rotate: 6deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-rotate-\[-6deg\] {

    --tw-rotate: -6deg;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-scale-100 {

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-scale-50 {

    --tw-scale-x: .5;

    --tw-scale-y: .5;

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-transform {

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-cursor-pointer {

    cursor: pointer
}
.tw-list-none {

    list-style-type: none
}
.tw-grid-cols-1 {

    grid-template-columns: repeat(1, minmax(0, 1fr))
}
.tw-grid-cols-3 {

    grid-template-columns: repeat(3, minmax(0, 1fr))
}
.tw-flex-row {

    flex-direction: row
}
.tw-flex-row-reverse {

    flex-direction: row-reverse
}
.tw-flex-col {

    flex-direction: column
}
.tw-flex-col-reverse {

    flex-direction: column-reverse
}
.tw-flex-wrap {

    flex-wrap: wrap
}
.tw-content-center {

    align-content: center
}
.tw-items-start {

    align-items: flex-start
}
.tw-items-center {

    align-items: center
}
.tw-items-baseline {

    align-items: baseline
}
.tw-items-stretch {

    align-items: stretch
}
.tw-justify-start {

    justify-content: flex-start
}
.tw-justify-end {

    justify-content: flex-end
}
.tw-justify-center {

    justify-content: center
}
.tw-justify-between {

    justify-content: space-between
}
.tw-justify-evenly {

    justify-content: space-evenly
}
.tw-gap-1 {

    gap: 0.25rem
}
.tw-gap-12 {

    gap: 3rem
}
.tw-gap-2 {

    gap: 0.5rem
}
.tw-gap-3 {

    gap: 0.75rem
}
.tw-gap-4 {

    gap: 1rem
}
.tw-gap-5 {

    gap: 1.25rem
}
.tw-gap-6 {

    gap: 1.5rem
}
.tw-gap-8 {

    gap: 2rem
}
.tw-gap-x-4 {

    column-gap: 1rem
}
.tw-gap-y-8 {

    row-gap: 2rem
}
.tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(0.75rem * var(--tw-space-x-reverse));

    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}
.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(1rem * var(--tw-space-x-reverse));

    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}
.tw-space-x-5 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(1.25rem * var(--tw-space-x-reverse));

    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
}
.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1rem * var(--tw-space-y-reverse))
}
.tw-space-y-6 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse))
}
.tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}
.tw-space-y-reverse > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 1
}
.tw-divide-x > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-x-reverse: 0;

    border-right-width: calc(1px * var(--tw-divide-x-reverse));

    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}
.tw-divide-y > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-y-reverse: 0;

    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));

    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}
.tw-divide-y-2 > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-y-reverse: 0;

    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));

    border-bottom-width: calc(2px * var(--tw-divide-y-reverse))
}
.tw-divide-dashed > :not([hidden]) ~ :not([hidden]) {

    border-style: dashed
}
.tw-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-opacity: 1;

    border-color: rgb(147 197 253 / var(--tw-divide-opacity))
}
.tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-opacity: 1;

    border-color: rgb(229 231 235 / var(--tw-divide-opacity))
}
.tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-opacity: 1;

    border-color: rgb(209 213 219 / var(--tw-divide-opacity))
}
.tw-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-opacity: 1;

    border-color: rgb(156 163 175 / var(--tw-divide-opacity))
}
.tw-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-opacity: 1;

    border-color: rgb(31 41 55 / var(--tw-divide-opacity))
}
.tw-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-opacity: 1;

    border-color: rgb(165 180 252 / var(--tw-divide-opacity))
}
.tw-divide-white > :not([hidden]) ~ :not([hidden]) {

    --tw-divide-opacity: 1;

    border-color: rgb(255 255 255 / var(--tw-divide-opacity))
}
.tw-self-center {

    align-self: center
}
.tw-self-stretch {

    align-self: stretch
}
.tw-justify-self-stretch {

    justify-self: stretch
}
.tw-overflow-hidden {

    overflow: hidden
}
.tw-overflow-y-auto {

    overflow-y: auto
}
.tw-overflow-y-scroll {

    overflow-y: scroll
}
.tw-truncate {

    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap
}
.tw-whitespace-nowrap {

    white-space: nowrap
}
.tw-whitespace-pre-line {

    white-space: pre-line
}
.tw-rounded {

    border-radius: 0.25rem
}
.tw-rounded-3xl {

    border-radius: 1.5rem
}
.tw-rounded-full {

    border-radius: 9999px
}
.tw-rounded-lg {

    border-radius: 0.5rem
}
.tw-rounded-md {

    border-radius: 0.375rem
}
.tw-rounded-none {

    border-radius: 0px
}
.tw-rounded-sm {

    border-radius: 0.125rem
}
.tw-rounded-xl {

    border-radius: 0.75rem
}
.tw-rounded-l-md {

    border-top-left-radius: 0.375rem;

    border-bottom-left-radius: 0.375rem
}
.tw-rounded-l-none {

    border-top-left-radius: 0px;

    border-bottom-left-radius: 0px
}
.tw-rounded-r-lg {

    border-top-right-radius: 0.5rem;

    border-bottom-right-radius: 0.5rem
}
.tw-rounded-r-md {

    border-top-right-radius: 0.375rem;

    border-bottom-right-radius: 0.375rem
}
.tw-rounded-r-none {

    border-top-right-radius: 0px;

    border-bottom-right-radius: 0px
}
.tw-border {

    border-width: 1px
}
.tw-border-0 {

    border-width: 0px
}
.tw-border-2 {

    border-width: 2px
}
.tw-border-4 {

    border-width: 4px
}
.tw-border-b {

    border-bottom-width: 1px
}
.tw-border-b-2 {

    border-bottom-width: 2px
}
.tw-border-l {

    border-left-width: 1px
}
.tw-border-r-0 {

    border-right-width: 0px
}
.tw-border-t {

    border-top-width: 1px
}
.tw-border-t-2 {

    border-top-width: 2px
}
.tw-border-solid {

    border-style: solid
}
.tw-border-dashed {

    border-style: dashed
}
.tw-border-none {

    border-style: none
}
.tw-border-black {

    --tw-border-opacity: 1;

    border-color: rgb(0 0 0 / var(--tw-border-opacity))
}
.tw-border-fzblue {

    --tw-border-opacity: 1;

    border-color: rgb(55 101 176 / var(--tw-border-opacity))
}
.tw-border-fzsky {

    --tw-border-opacity: 1;

    border-color: rgb(173 193 224 / var(--tw-border-opacity))
}
.tw-border-gray-200 {

    --tw-border-opacity: 1;

    border-color: rgb(229 231 235 / var(--tw-border-opacity))
}
.tw-border-gray-300 {

    --tw-border-opacity: 1;

    border-color: rgb(209 213 219 / var(--tw-border-opacity))
}
.tw-border-gray-600 {

    --tw-border-opacity: 1;

    border-color: rgb(75 85 99 / var(--tw-border-opacity))
}
.tw-border-gray-800 {

    --tw-border-opacity: 1;

    border-color: rgb(31 41 55 / var(--tw-border-opacity))
}
.tw-border-indigo-600 {

    --tw-border-opacity: 1;

    border-color: rgb(79 70 229 / var(--tw-border-opacity))
}
.tw-border-red-600 {

    --tw-border-opacity: 1;

    border-color: rgb(220 38 38 / var(--tw-border-opacity))
}
.tw-border-slate-200 {

    --tw-border-opacity: 1;

    border-color: rgb(226 232 240 / var(--tw-border-opacity))
}
.tw-border-slate-300 {

    --tw-border-opacity: 1;

    border-color: rgb(203 213 225 / var(--tw-border-opacity))
}
.tw-border-slate-700 {

    --tw-border-opacity: 1;

    border-color: rgb(51 65 85 / var(--tw-border-opacity))
}
.tw-border-transparent {

    border-color: transparent
}
.tw-border-white {

    --tw-border-opacity: 1;

    border-color: rgb(255 255 255 / var(--tw-border-opacity))
}
.tw-border-yellow-500 {

    --tw-border-opacity: 1;

    border-color: rgb(234 179 8 / var(--tw-border-opacity))
}
.tw-bg-blue-900 {

    --tw-bg-opacity: 1;

    background-color: rgb(30 58 138 / var(--tw-bg-opacity))
}
.tw-bg-blue-900\/50 {

    background-color: rgb(30 58 138 / 0.5)
}
.tw-bg-bluegray {

    --tw-bg-opacity: 1;

    background-color: rgb(81 83 107 / var(--tw-bg-opacity))
}
.tw-bg-fzblue {

    --tw-bg-opacity: 1;

    background-color: rgb(55 101 176 / var(--tw-bg-opacity))
}
.tw-bg-gray-100 {

    --tw-bg-opacity: 1;

    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.tw-bg-gray-200 {

    --tw-bg-opacity: 1;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}
.tw-bg-gray-50 {

    --tw-bg-opacity: 1;

    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}
.tw-bg-green-100 {

    --tw-bg-opacity: 1;

    background-color: rgb(220 252 231 / var(--tw-bg-opacity))
}
.tw-bg-green-400 {

    --tw-bg-opacity: 1;

    background-color: rgb(74 222 128 / var(--tw-bg-opacity))
}
.tw-bg-indigo-300 {

    --tw-bg-opacity: 1;

    background-color: rgb(165 180 252 / var(--tw-bg-opacity))
}
.tw-bg-indigo-500 {

    --tw-bg-opacity: 1;

    background-color: rgb(99 102 241 / var(--tw-bg-opacity))
}
.tw-bg-indigo-600 {

    --tw-bg-opacity: 1;

    background-color: rgb(79 70 229 / var(--tw-bg-opacity))
}
.tw-bg-offwhite {

    --tw-bg-opacity: 1;

    background-color: rgb(251 250 255 / var(--tw-bg-opacity))
}
.tw-bg-pink-400 {

    --tw-bg-opacity: 1;

    background-color: rgb(244 114 182 / var(--tw-bg-opacity))
}
.tw-bg-red-100 {

    --tw-bg-opacity: 1;

    background-color: rgb(254 226 226 / var(--tw-bg-opacity))
}
.tw-bg-red-400 {

    --tw-bg-opacity: 1;

    background-color: rgb(248 113 113 / var(--tw-bg-opacity))
}
.tw-bg-slate-200 {

    --tw-bg-opacity: 1;

    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}
.tw-bg-slate-500 {

    --tw-bg-opacity: 1;

    background-color: rgb(100 116 139 / var(--tw-bg-opacity))
}
.tw-bg-slate-600 {

    --tw-bg-opacity: 1;

    background-color: rgb(71 85 105 / var(--tw-bg-opacity))
}
.tw-bg-slate-700 {

    --tw-bg-opacity: 1;

    background-color: rgb(51 65 85 / var(--tw-bg-opacity))
}
.tw-bg-slate-800\/50 {

    background-color: rgb(30 41 59 / 0.5)
}
.tw-bg-slate-900 {

    --tw-bg-opacity: 1;

    background-color: rgb(15 23 42 / var(--tw-bg-opacity))
}
.tw-bg-teal-500 {

    --tw-bg-opacity: 1;

    background-color: rgb(20 184 166 / var(--tw-bg-opacity))
}
.tw-bg-transparent {

    background-color: transparent
}
.tw-bg-white {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.tw-bg-yellow-400 {

    --tw-bg-opacity: 1;

    background-color: rgb(250 204 21 / var(--tw-bg-opacity))
}
.tw-bg-none {

    background-image: none
}
.tw-fill-white {

    fill: #fff
}
.tw-p-0 {

    padding: 0px
}
.tw-p-1 {

    padding: 0.25rem
}
.tw-p-2 {

    padding: 0.5rem
}
.tw-p-3 {

    padding: 0.75rem
}
.tw-p-4 {

    padding: 1rem
}
.tw-p-5 {

    padding: 1.25rem
}
.tw-p-6 {

    padding: 1.5rem
}
.tw-p-8 {

    padding: 2rem
}
.tw-px-0 {

    padding-left: 0px;

    padding-right: 0px
}
.tw-px-1 {

    padding-left: 0.25rem;

    padding-right: 0.25rem
}
.tw-px-1\.5 {

    padding-left: 0.375rem;

    padding-right: 0.375rem
}
.tw-px-2 {

    padding-left: 0.5rem;

    padding-right: 0.5rem
}
.tw-px-3 {

    padding-left: 0.75rem;

    padding-right: 0.75rem
}
.tw-px-4 {

    padding-left: 1rem;

    padding-right: 1rem
}
.tw-px-5 {

    padding-left: 1.25rem;

    padding-right: 1.25rem
}
.tw-px-6 {

    padding-left: 1.5rem;

    padding-right: 1.5rem
}
.tw-px-8 {

    padding-left: 2rem;

    padding-right: 2rem
}
.tw-py-0 {

    padding-top: 0px;

    padding-bottom: 0px
}
.tw-py-0\.5 {

    padding-top: 0.125rem;

    padding-bottom: 0.125rem
}
.tw-py-1 {

    padding-top: 0.25rem;

    padding-bottom: 0.25rem
}
.tw-py-1\.5 {

    padding-top: 0.375rem;

    padding-bottom: 0.375rem
}
.tw-py-2 {

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}
.tw-py-3 {

    padding-top: 0.75rem;

    padding-bottom: 0.75rem
}
.tw-py-3\.5 {

    padding-top: 0.875rem;

    padding-bottom: 0.875rem
}
.tw-py-4 {

    padding-top: 1rem;

    padding-bottom: 1rem
}
.tw-py-5 {

    padding-top: 1.25rem;

    padding-bottom: 1.25rem
}
.tw-py-6 {

    padding-top: 1.5rem;

    padding-bottom: 1.5rem
}
.tw-py-8 {

    padding-top: 2rem;

    padding-bottom: 2rem
}
.tw-pb-0 {

    padding-bottom: 0px
}
.tw-pb-10 {

    padding-bottom: 2.5rem
}
.tw-pb-12 {

    padding-bottom: 3rem
}
.tw-pb-2 {

    padding-bottom: 0.5rem
}
.tw-pb-3 {

    padding-bottom: 0.75rem
}
.tw-pb-4 {

    padding-bottom: 1rem
}
.tw-pb-5 {

    padding-bottom: 1.25rem
}
.tw-pb-6 {

    padding-bottom: 1.5rem
}
.tw-pl-3 {

    padding-left: 0.75rem
}
.tw-pl-6 {

    padding-left: 1.5rem
}
.tw-pl-8 {

    padding-left: 2rem
}
.tw-pr-16 {

    padding-right: 4rem
}
.tw-pr-2 {

    padding-right: 0.5rem
}
.tw-pr-8 {

    padding-right: 2rem
}
.tw-pt-1 {

    padding-top: 0.25rem
}
.tw-pt-12 {

    padding-top: 3rem
}
.tw-pt-2 {

    padding-top: 0.5rem
}
.tw-pt-4 {

    padding-top: 1rem
}
.tw-pt-5 {

    padding-top: 1.25rem
}
.tw-pt-8 {

    padding-top: 2rem
}
.tw-text-left {

    text-align: left
}
.tw-text-center {

    text-align: center
}
.tw-text-right {

    text-align: right
}
.tw-align-middle {

    vertical-align: middle
}
.tw-text-3xl {

    font-size: 1.875rem;

    line-height: 2.25rem
}
.tw-text-base {

    font-size: 1rem;

    line-height: 1.5rem
}
.tw-text-lg {

    font-size: 1.125rem;

    line-height: 1.75rem
}
.tw-text-sm {

    font-size: 0.875rem;

    line-height: 1.25rem
}
.tw-text-xs {

    font-size: 0.75rem;

    line-height: 1rem
}
.tw-font-bold {

    font-weight: 700
}
.tw-font-medium {

    font-weight: 500
}
.tw-font-semibold {

    font-weight: 600
}
.tw-uppercase {

    text-transform: uppercase
}
.tw-capitalize {

    text-transform: capitalize
}
.tw-leading-5 {

    line-height: 1.25rem
}
.tw-leading-none {

    line-height: 1
}
.tw-tracking-\[\.08rem\] {

    letter-spacing: .08rem
}
.tw-tracking-wider {

    letter-spacing: 0.05em
}
.tw-text-black {

    --tw-text-opacity: 1;

    color: rgb(0 0 0 / var(--tw-text-opacity))
}
.tw-text-bluegray {

    --tw-text-opacity: 1;

    color: rgb(81 83 107 / var(--tw-text-opacity))
}
.tw-text-fzblue {

    --tw-text-opacity: 1;

    color: rgb(55 101 176 / var(--tw-text-opacity))
}
.tw-text-gray-300 {

    --tw-text-opacity: 1;

    color: rgb(209 213 219 / var(--tw-text-opacity))
}
.tw-text-gray-400 {

    --tw-text-opacity: 1;

    color: rgb(156 163 175 / var(--tw-text-opacity))
}
.tw-text-gray-500 {

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}
.tw-text-gray-600 {

    --tw-text-opacity: 1;

    color: rgb(75 85 99 / var(--tw-text-opacity))
}
.tw-text-gray-700 {

    --tw-text-opacity: 1;

    color: rgb(55 65 81 / var(--tw-text-opacity))
}
.tw-text-gray-800 {

    --tw-text-opacity: 1;

    color: rgb(31 41 55 / var(--tw-text-opacity))
}
.tw-text-gray-900 {

    --tw-text-opacity: 1;

    color: rgb(17 24 39 / var(--tw-text-opacity))
}
.tw-text-green-800 {

    --tw-text-opacity: 1;

    color: rgb(22 101 52 / var(--tw-text-opacity))
}
.tw-text-indigo-600 {

    --tw-text-opacity: 1;

    color: rgb(79 70 229 / var(--tw-text-opacity))
}
.tw-text-offwhite {

    --tw-text-opacity: 1;

    color: rgb(251 250 255 / var(--tw-text-opacity))
}
.tw-text-red-800 {

    --tw-text-opacity: 1;

    color: rgb(153 27 27 / var(--tw-text-opacity))
}
.tw-text-slate-700 {

    --tw-text-opacity: 1;

    color: rgb(51 65 85 / var(--tw-text-opacity))
}
.tw-text-slate-900 {

    --tw-text-opacity: 1;

    color: rgb(15 23 42 / var(--tw-text-opacity))
}
.tw-text-transparent {

    color: transparent
}
.tw-text-white {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.tw-underline {

    text-decoration-line: underline
}
.tw-line-through {

    text-decoration-line: line-through
}
.tw-placeholder-gray-500::placeholder {

    --tw-placeholder-opacity: 1;

    color: rgb(107 114 128 / var(--tw-placeholder-opacity))
}
.tw-opacity-0 {

    opacity: 0
}
.tw-opacity-100 {

    opacity: 1
}
.tw-shadow {

    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.tw-shadow-lg {

    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.tw-shadow-md {

    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.tw-shadow-sm {

    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.tw-shadow-xl {

    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.tw-outline-none {

    outline: 2px solid transparent;

    outline-offset: 2px
}
.tw-transition {

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}
.tw-transition-opacity {

    transition-property: opacity;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}
.tw-delay-100 {

    transition-delay: 100ms
}
.tw-duration-0 {

    transition-duration: 0s
}
.tw-duration-150 {

    transition-duration: 150ms
}
.tw-duration-200 {

    transition-duration: 200ms
}
.tw-duration-500 {

    transition-duration: 500ms
}
.tw-duration-75 {

    transition-duration: 75ms
}
.tw-ease-in-out {

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
.focus-within\:tw-border-indigo-600:focus-within {

    --tw-border-opacity: 1;

    border-color: rgb(79 70 229 / var(--tw-border-opacity))
}
.focus-within\:tw-ring-1:focus-within {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
.focus-within\:tw-ring-2:focus-within {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
.focus-within\:tw-ring-inset:focus-within {

    --tw-ring-inset: inset
}
.focus-within\:tw-ring-blue-600:focus-within {

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity))
}
.focus-within\:tw-ring-indigo-600:focus-within {

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity))
}
.hover\:tw-cursor-pointer:hover {

    cursor: pointer
}
.hover\:tw-bg-blue-400\/50:hover {

    background-color: rgb(96 165 250 / 0.5)
}
.hover\:tw-bg-fzsky:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(173 193 224 / var(--tw-bg-opacity))
}
.hover\:tw-bg-gray-100:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.hover\:tw-bg-gray-200:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}
.hover\:tw-bg-gray-50:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}
.hover\:tw-bg-green-400:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(74 222 128 / var(--tw-bg-opacity))
}
.hover\:tw-bg-indigo-500:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(99 102 241 / var(--tw-bg-opacity))
}
.hover\:tw-bg-indigo-600:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(79 70 229 / var(--tw-bg-opacity))
}
.hover\:tw-bg-offwhite:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(251 250 255 / var(--tw-bg-opacity))
}
.hover\:tw-bg-red-300:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(252 165 165 / var(--tw-bg-opacity))
}
.hover\:tw-bg-red-400:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(248 113 113 / var(--tw-bg-opacity))
}
.hover\:tw-bg-slate-600:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(71 85 105 / var(--tw-bg-opacity))
}
.hover\:tw-font-bold:hover {

    font-weight: 700
}
.hover\:tw-text-blueGray-900:hover {

    --tw-text-opacity: 1;

    color: rgb(15 23 42 / var(--tw-text-opacity))
}
.hover\:tw-text-fzblue:hover {

    --tw-text-opacity: 1;

    color: rgb(55 101 176 / var(--tw-text-opacity))
}
.hover\:tw-text-gray-700:hover {

    --tw-text-opacity: 1;

    color: rgb(55 65 81 / var(--tw-text-opacity))
}
.hover\:tw-text-indigo-500:hover {

    --tw-text-opacity: 1;

    color: rgb(99 102 241 / var(--tw-text-opacity))
}
.hover\:tw-text-slate-700:hover {

    --tw-text-opacity: 1;

    color: rgb(51 65 85 / var(--tw-text-opacity))
}
.hover\:tw-text-slate-800:hover {

    --tw-text-opacity: 1;

    color: rgb(30 41 59 / var(--tw-text-opacity))
}
.hover\:tw-text-white:hover {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.hover\:tw-shadow-lg:hover {

    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.hover\:tw-shadow-sm:hover {

    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.hover\:tw-ring:hover {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
.hover\:tw-ring-fzsky:hover {

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(173 193 224 / var(--tw-ring-opacity))
}
.focus\:tw-border-indigo-500:focus {

    --tw-border-opacity: 1;

    border-color: rgb(99 102 241 / var(--tw-border-opacity))
}
.focus\:tw-outline-none:focus {

    outline: 2px solid transparent;

    outline-offset: 2px
}
.focus\:tw-ring-0:focus {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
.focus\:tw-ring-2:focus {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}
.focus\:tw-ring-indigo-500:focus {

    --tw-ring-opacity: 1;

    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity))
}
.focus\:tw-ring-offset-2:focus {

    --tw-ring-offset-width: 2px
}
.disabled\:tw-bg-gray-400:disabled {

    --tw-bg-opacity: 1;

    background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}
.disabled\:tw-text-slate-700:disabled {

    --tw-text-opacity: 1;

    color: rgb(51 65 85 / var(--tw-text-opacity))
}
.disabled\:tw-opacity-25:disabled {

    opacity: 0.25
}
.disabled\:tw-shadow-none:disabled {

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
@media (min-width: 640px) {

    .sm\:tw-col-span-2 {

        grid-column: span 2 / span 2
    }

    .sm\:tw-col-span-6 {

        grid-column: span 6 / span 6
    }

    .sm\:tw-flex {

        display: flex
    }

    .sm\:tw-grid-cols-1 {

        grid-template-columns: repeat(1, minmax(0, 1fr))
    }

    .sm\:tw-grid-cols-2 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .sm\:tw-flex-row-reverse {

        flex-direction: row-reverse
    }

    .sm\:tw-items-center {

        align-items: center
    }

    .sm\:tw-justify-end {

        justify-content: flex-end
    }

    .sm\:tw-justify-between {

        justify-content: space-between
    }

    .sm\:tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-x-reverse: 0;

        margin-right: calc(0.75rem * var(--tw-space-x-reverse));

        margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
    }

    .sm\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(0px * var(--tw-space-y-reverse))
    }

    .sm\:tw-space-x-reverse > :not([hidden]) ~ :not([hidden]) {

        --tw-space-x-reverse: 1
    }

    .sm\:tw-overflow-hidden {

        overflow: hidden
    }

    .sm\:tw-rounded-lg {

        border-radius: 0.5rem
    }

    .sm\:tw-rounded-md {

        border-radius: 0.375rem
    }

    .sm\:tw-px-6 {

        padding-left: 1.5rem;

        padding-right: 1.5rem
    }

    .sm\:tw-pb-7 {

        padding-bottom: 1.75rem
    }

    .sm\:tw-pt-6 {

        padding-top: 1.5rem
    }

    .sm\:tw-duration-700 {

        transition-duration: 700ms
    }
}
@media (min-width: 768px) {

    .md\:tw-mb-12 {

        margin-bottom: 3rem
    }

    .md\:tw-mt-0 {

        margin-top: 0px
    }

    .md\:tw-flex {

        display: flex
    }

    .md\:tw-grid-cols-3 {

        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .md\:tw-flex-row {

        flex-direction: row
    }

    .md\:tw-items-center {

        align-items: center
    }

    .md\:tw-justify-between {

        justify-content: space-between
    }

    .md\:tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-x-reverse: 0;

        margin-right: calc(0.75rem * var(--tw-space-x-reverse));

        margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
    }

    .md\:tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-x-reverse: 0;

        margin-right: calc(1rem * var(--tw-space-x-reverse));

        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
    }

    .md\:tw-space-x-5 > :not([hidden]) ~ :not([hidden]) {

        --tw-space-x-reverse: 0;

        margin-right: calc(1.25rem * var(--tw-space-x-reverse));

        margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
    }
}
@media (min-width: 1024px) {

    .lg\:tw-fixed {

        position: fixed
    }

    .lg\:tw-inset-y-0 {

        top: 0px;

        bottom: 0px
    }

    .lg\:tw-col-span-1 {

        grid-column: span 1 / span 1
    }

    .lg\:tw-col-span-2 {

        grid-column: span 2 / span 2
    }

    .lg\:tw-col-start-1 {

        grid-column-start: 1
    }

    .lg\:tw-col-start-3 {

        grid-column-start: 3
    }

    .lg\:tw-flex {

        display: flex
    }

    .lg\:tw-w-72 {

        width: 18rem
    }

    .lg\:tw-max-w-7xl {

        max-width: 80rem
    }

    .lg\:tw-grid-flow-col-dense {

        grid-auto-flow: column dense
    }

    .lg\:tw-grid-cols-3 {

        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .lg\:tw-flex-col {

        flex-direction: column
    }

    .lg\:tw-px-8 {

        padding-left: 2rem;

        padding-right: 2rem
    }

    .lg\:tw-pl-80 {

        padding-left: 20rem
    }
}
@media (min-width: 1280px) {

    .xl\:tw-col-span-4 {

        grid-column: span 4 / span 4
    }

    .xl\:tw-mt-10 {

        margin-top: 2.5rem
    }

    .xl\:tw-block {

        display: block
    }

    .xl\:tw-hidden {

        display: none
    }

    .xl\:tw-border-b {

        border-bottom-width: 1px
    }

    .xl\:tw-border-r {

        border-right-width: 1px
    }

    .xl\:tw-border-gray-200 {

        --tw-border-opacity: 1;

        border-color: rgb(229 231 235 / var(--tw-border-opacity))
    }

    .xl\:tw-py-10 {

        padding-top: 2.5rem;

        padding-bottom: 2.5rem
    }

    .xl\:tw-pb-0 {

        padding-bottom: 0px
    }

    .xl\:tw-pb-6 {

        padding-bottom: 1.5rem
    }

    .xl\:tw-pl-8 {

        padding-left: 2rem
    }

    .xl\:tw-pr-8 {

        padding-right: 2rem
    }

    .xl\:tw-pt-6 {

        padding-top: 1.5rem
    }
}
@media (prefers-color-scheme: dark) {

    .dark\:tw-border-slate-400 {

        --tw-border-opacity: 1;

        border-color: rgb(148 163 184 / var(--tw-border-opacity))
    }

    .dark\:tw-border-slate-800 {

        --tw-border-opacity: 1;

        border-color: rgb(30 41 59 / var(--tw-border-opacity))
    }

    .dark\:tw-border-slate-900 {

        --tw-border-opacity: 1;

        border-color: rgb(15 23 42 / var(--tw-border-opacity))
    }

    .dark\:tw-bg-slate-500 {

        --tw-bg-opacity: 1;

        background-color: rgb(100 116 139 / var(--tw-bg-opacity))
    }

    .dark\:tw-bg-slate-700 {

        --tw-bg-opacity: 1;

        background-color: rgb(51 65 85 / var(--tw-bg-opacity))
    }

    .dark\:tw-bg-slate-800 {

        --tw-bg-opacity: 1;

        background-color: rgb(30 41 59 / var(--tw-bg-opacity))
    }

    .dark\:tw-text-white {

        --tw-text-opacity: 1;

        color: rgb(255 255 255 / var(--tw-text-opacity))
    }

    .hover\:dark\:tw-border-fzblue:hover {

        --tw-border-opacity: 1;

        border-color: rgb(55 101 176 / var(--tw-border-opacity))
    }

    .hover\:dark\:tw-bg-fzblue:hover {

        --tw-bg-opacity: 1;

        background-color: rgb(55 101 176 / var(--tw-bg-opacity))
    }

    .dark\:hover\:tw-text-blue-400:hover {

        --tw-text-opacity: 1;

        color: rgb(96 165 250 / var(--tw-text-opacity))
    }

    .hover\:dark\:tw-text-white:hover {

        --tw-text-opacity: 1;

        color: rgb(255 255 255 / var(--tw-text-opacity))
    }
}
