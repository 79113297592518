@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes badge_equip {
  0% {
    box-shadow: 0 0 36px 24px gold;
    transform: scale(1.3) rotate(-10deg);
  }
  10% {
    transform: scale(1.3) rotate(-10deg);
  }
  60% {
    transform: scale(1.3) rotate(0);
  }
  90% {
    transform: scale(0.85) rotate(10deg) translateY(15px);
  }
  100% {
    box-shadow: 0 0 8px 4px gold;
    transform: scale(1) rotate(0deg) translateY(0);
  }
}

@keyframes breathing_gold {
  0% {
    box-shadow: 0 0 6px 2px gold;
  }
  75% {
    box-shadow: 0 0 8px 4px gold;
  }
  100% {
    box-shadow: 0 0 12px 4px gold;
  }
}

@keyframes breathing_blue {
  0% {
    box-shadow: 0 0 6px 2px var(--sub-blue);
  }
  75% {
    box-shadow: 0 0 8px 4px var(--sub-blue);
  }
  100% {
    box-shadow: 0 0 12px 4px var(--sub-blue);
  }
}

@keyframes radar {
  0% {
    opacity: 1;
    transform: scale(0.1);
    border: 2px solid var(--yellow);
    border-radius: 5000px;
    box-shadow: 0 0 4px 2px var(--yellow), inset 0 0 4px 2px var(--yellow);
  }
  80% {
    opacity: 0.6;
    border: 1px solid var(--yellow);
    box-shadow: 0 0 2px 1px var(--yellow), inset 0 0 2px 1px var(--yellow);
  }
  100% {
    opacity: 0;
    transform: scale(5.5);
    border: 0.7px solid var(--yellow);
    border-width: 1px;
    border-radius: 5000px;
    box-shadow: 0 0 1px 0.5px var(--yellow), inset 0 0 1px 0.5px var(--yellow);
  }
}

.radar-blip:after {
  content: '';
  position: absolute;
  display: block;
  top: calc(50% - 10px);
  right: calc(50% - 10px);
  width: 20px;
  height: 20px;
  z-index: -1;
  animation-name: radar;
  animation-duration: 2.5s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

@keyframes snoring {
  0% {
    background-size: 185px 115px;
  }
  85% {
    background-size: 175px 100px;
  }
  100% {
    background-size: 175px 100px;
  }
} 

@keyframes grow_open_vertical {
  0% {
    max-height: 145px;
  }
  100% {
    max-height: 1000px;
  }
}

@-webkit-keyframes placeholder_shimmer {
  0% { background-position: 10% 0% }
  50% { background-position: 91% 100% }
  100% { background-position: 10% 0% }
}
@-moz-keyframes placeholder_shimmer {
  0% { background-position: 10% 0% }
  50% { background-position: 91% 100% }
  100% { background-position: 10% 0% }
}
@keyframes placeholder_shimmer { 
  0% { background-position: 10% 0% }
  50% { background-position: 91% 100% }
  100% { background-position: 10% 0% }
}

@keyframes horizontalShake {
  from {
    transform: translateX(3px);
  }
  to {
    transform: translateX(-3px);
  }
}

.shakeIt {
  animation-duration: 0.05s; /* length of time for each shake */
  animation-iteration-count: 7; /* how many shakes to shake */
  animation-direction: alternate-reverse;
  animation-timing-function: linear;
}

.horizontalShake {
    animation-name: horizontalShake;
}

.fill-enter {
  opacity: 0.3;
}
.fill-enter-active {
  opacity: 1;
  border-radius: 50px;
  transition: all 150ms ease-in;
}
.fill-exit {
  opacity: 0.8;
}
.fill-exit-active {
  opacity: 0;
  transition: all 150ms ease-in;
  border-radius: 50px;
}

.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: var(--jfz-blue);
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: var(--jfz-blue);
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
